












import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    props: {
        title: String,
        icon: String,
        effect: {
            type: String,
            default: 'dark'
        }
    },
    setup() {}
})
