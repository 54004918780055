export const projectIndustryOption = [
    'ALCOHOL',
    'APPAREL/FOOTWEAR/JEWELRY',
    'ASSOCIATIONS / INSTITUTIONS / UNIONS',
    'AUTOMOTIVE',
    'BUSINESS & MANUFACTURING EQUIP/SUPPLY',
    'COMPUTERS & SOFTWARE',
    'ENTERTAINMENT & AMUSEMENTS',
    'FINANCIAL SERVICES',
    'FOOD & BEVERAGES',
    'GASOLINE & OIL',
    'GOVERNMENT',
    'HEALTH & BEAUTY PRODUCTS',
    'HEALTH SERVICES',
    'HOUSEHOLD CONSUMABLES',
    'HOUSEHOLD DURABLES',
    'MARKETING & ADVERTISING',
    'MEDIA NEWS/ENTERTAINMENT',
    'MEDIA PUBLISHING/OWNERS',
    'PETS/ PET FOOD & SUPPLIES',
    'PHARMACY/MEDICAL SUPPLY',
    'PUBLIC SERVICES & UTILITIES',
    'REAL ESTATE',
    'RESTAURANTS',
    'RETAIL',
    'SCHOOLS & EDUCATION',
    'SPORTING GOODS/TOYS/GAMES/ELECTRONICS',
    'TELECOMMUNICATIONS',
    'TOBACCO',
    'TRAVEL'
]
